<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card>
          <template v-slot:heading>
            <h3 class="display-2">Monitoring Pegawai</h3>
          </template>
          <v-toolbar class="mb-2" flat>
            <div style="max-width:400px">
              <v-autocomplete v-model="selectSKPD" :items="skpd" label="SKPD" item-text="v" item-value="k" hide-details></v-autocomplete>
            </div>
            <v-spacer></v-spacer>
            <div class="mt-5">
              <v-menu
                v-model="fromDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                transition="scale-transition"
                lazy offset-y full-width>
                <template v-slot:activator="{ on }">
                  <v-text-field v-show="!showItem" label="Tanggal Awal" append-icon="mdi-calendar" dense readonly v-model="tanggal_awal" v-on="on"></v-text-field>
                </template>
                <v-date-picker
                locale="in-ID"
                v-model="tanggal_awal"
                no-title
                @input="fromDateMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </div>
            <v-spacer></v-spacer>
            <div class="mt-5">
            <v-menu
              class="mt-5"
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="290px"
              transition="scale-transition"
              lazy offset-y full-width>
              <template v-slot:activator="{ on }">
                <v-text-field v-show="!showItem" label="Tanggal Akhir" append-icon="mdi-calendar" dense readonly v-model="tanggal_akhir" v-on="on"></v-text-field>
              </template>
              <v-date-picker
              locale="in-ID"
              v-model="tanggal_akhir"
              no-title
              @input="endDateMenu = false"
              >
              </v-date-picker>
            </v-menu>
            </div>
            <v-spacer></v-spacer>
            <v-btn @click="cari()" color="blue" dark><v-icon>mdi-magnify</v-icon> Cari</v-btn>
            <v-spacer></v-spacer>
            <v-btn medium color="primary" dark @click="exportExcel()"><v-icon>mdi-file-excel</v-icon> Export to Excel</v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[5,10,15]}"
            hide-default-header
            class="pegawai-table"
            :loading="loading"
          >
            <template v-slot:header>
              <thead class="blue">
                <tr>
                  <th class="font-weight-light white--text text-center" rowspan="2">No</th>
                  <th class="font-weight-light white--text text-center" rowspan="2">Nama</th>
                  <th class="font-weight-light white--text text-center" rowspan="2">NIP</th>
                  <th class="font-weight-light white--text text-center" rowspan="2">Jabatan</th>
                  <th class="font-weight-light white--text text-center" rowspan="2">Status Online</th>
                  <th class="font-weight-light white--text text-center" rowspan="2">Last Login</th>
                  <th class="font-weight-light white--text text-center" colspan="9">Rekapitulasi Jumlah Aktivitas</th>
                  <th class="font-weight-light white--text" rowspan="2">Pangkat/Gol</th>
                  <th class="font-weight-light white--text" rowspan="2">Eselon</th>
                  <th class="font-weight-light white--text" rowspan="2">Jenis Pegawai</th>
                  <th class="font-weight-light white--text" rowspan="2">Status</th>
                  <th class="font-weight-light white--text" rowspan="2">Keterangan</th>
                  <th class="font-weight-light white--text" rowspan="2">Jml Bawahan Langsung</th>
                  <th class="font-weight-light white--text" rowspan="2">Aktivasi Login</th>
                  <th class="font-weight-light white--text" rowspan="2">Detail</th>
                </tr>
                <tr>
                  <th class="font-weight-light white--text">Setting Kegiatan</th>
                  <th class="font-weight-light white--text">Login</th>
                  <th class="font-weight-light white--text">Lapor Aktivitas Utama</th>
                  <th class="font-weight-light white--text">Menit Aktivitas Utama</th>
                  <th class="font-weight-light white--text">Lapor Aktivitas Tambahan</th>
                  <th class="font-weight-light white--text">Menit Aktivitas Tambahan</th>
                  <th class="font-weight-light white--text">Validasi Aktivitas</th>
                  <th class="font-weight-light white--text">Review Perilaku</th>
                  <th class="font-weight-light white--text">Input Sasaran Kerja</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.nama="{item}">
              {{item[0]}}
            </template>
            <template v-slot:item.nip="{item}">
              {{item[1]}}
            </template>
            <template v-slot:item.jabatan="{item}">
              {{item[2]}}
            </template>
            <template v-slot:item.status_online="{item}">
              {{item[3]}}
            </template>
            <template v-slot:item.last_login="{item}">
              {{item[4]}}
            </template>
            <template v-slot:item.setting_kegiatan="{item}">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ 'pink lighten-4': (item[5] == 0 || item[5] == null) }" style="height:100%;padding-top:15%">
                  {{item[5]}}
                </div>
              </td>
            </template>
            <template v-slot:item.login="{item}">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ 'pink lighten-4': (item[6] == 0 || item[6] == null) }" style="height:100%;padding-top:15%">
                  {{item[6]}}
                </div>
              </td>
            </template>
            <template v-slot:item.lapor_aktivitas_utama="{item}">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ 'pink lighten-4': (item[7] == 0 || item[7] == null) }" style="height:100%;padding-top:15%">
                  {{item[7]}}
                </div>
              </td>
            </template>
            <template v-slot:item.menit_aktivitas_utama="{item}">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ 'pink lighten-4': (item[8] == 0 || item[8] == null) }" style="height:100%;padding-top:15%">
                  {{item[8]}}
                </div>
              </td>
            </template>
            <template v-slot:item.lapor_aktivitas_tambahan="{item}">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ 'pink lighten-4': (item[9] == 0 || item[9] == null) }" style="height:100%;padding-top:15%">
                  {{item[9]}}
                </div>
              </td>
            </template>
            <template v-slot:item.menit_aktivitas_tambahan="{item}">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ 'pink lighten-4': (item[10] == 0 || item[10] == null) }" style="height:100%;padding-top:15%">
                  {{item[10]}}
                </div>
              </td>
            </template>
            <template v-slot:item.validasi_aktivitas="{item}">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ 'pink lighten-4': (item[11] == 0 || item[11] == null) }" style="height:100%;padding-top:15%">
                  {{item[11]}}
                </div>
              </td>
            </template>
            <template v-slot:item.review_perilaku="{item}">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ 'pink lighten-4': (item[12] == 0 || item[12] == null) }" style="height:100%;padding-top:15%">
                  {{item[12]}}
                </div>
              </td>
            </template>
            <template v-slot:item.input_sasaran_kerja="{item}">
              {{item[13]}}
            </template>
            <template v-slot:item.pangkat="{item}">
              {{item[14]}}
            </template>
            <template v-slot:item.eselon="{item}">
              {{item[15]}}
            </template>
            <template v-slot:item.jenis_pegawai="{item}">
              {{item[16]}}
            </template>
            <template v-slot:item.status="{item}">
              {{item[17]}}
            </template>
            <template v-slot:item.keterangan="{item}">
              {{item[18]}}
            </template>
            <template v-slot:item.jml_bawahan_lsg="{item}">
              {{item[19]}}
            </template>
            <template v-slot:item.aktivitas_login="{item}">
              {{item[20]}}
            </template>
            <template v-slot:item.detail="{item}">
              <v-btn color="yellow darken-1" small @click="detail(item)"><v-icon small>mdi-magnify</v-icon>Detail</v-btn>&nbsp;
              <v-btn color="primary" small @click="histori(item)"><v-icon small>mdi-pencil</v-icon>Histori</v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <!-- detail pegawai -->
    <v-dialog v-model="detailDialog" max-width="700px" v-if="detailData">
      <v-card >
        <v-card-title class="pa-0 mt-0">
          <v-toolbar flat>
            <v-toolbar-title>{{crudSettings.detailTitle}} <span class="font-weight-medium" ><q>{{detailData['Nama']}}</q> </span> </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="detailDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <loading v-if="detailLoading"></loading>
          <div v-else>
            <table class="text-body-1 font-weight-normal">
              <tr v-for="(v,k,i) in detailData" :key="i">
                <td v-if="!k.match(/noDisplay.*/)" class="px-5 py-2">{{k}}</td>
                <td v-else></td>
                <td v-html="v"></td>
              </tr>
            </table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- history pegawai -->
    <v-dialog v-model="detailDialogHistory" v-if="detailDataHistory">
      <v-card >
        <v-card-title class="pa-0 mt-0">
          <v-toolbar flat>
            <v-toolbar-title>Log Aktivitas <span class="font-weight-medium" ><q>{{pegNama}}</q> </span> </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="detailDialogHistory=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <loading v-if="detailLoadingHistory"></loading>
          <div v-else>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="searchHistory" hide-details label="Cari" append-icon="mdi-magnify"></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headersHistory"
              :items="itemsHistory"
              :loading="loadingHistory"
              :server-items-length="totalKeseluruhanHistory"
              :page.sync="pageHistory"
              :items-per-page.sync="rowPerPageHistory"
              :footer-props="{'items-per-page-options':[10,15,20]}"
            >
              <template v-slot:item.no="{item, index}">
                {{index+1}}
              </template>
              <template v-slot:item.aksi="{ item }">
                {{item.aksi}}
              </template>
              <template v-slot:item.keterangan="{ item }">
                {{item.keterangan}}
              </template>
              <template v-slot:item.waktu="{ item }">
                {{item.waktu}}
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="detailDialogHistory=false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Loading from '@/components/base/Loading'
import MonitoringPegawaiService from '@/services/MonitoringPegawaiService'
import store from '@/store'
import axios from 'axios'
import _ from 'lodash'
import _g from '../../global'
export default {
  components:{
    Loading
  },
  data(){
    return{
      toggle_triwulan:'',
      filterPegawai:true,
      forceUpdate:false,
      loading:false,
      detailData:{},
      skpd:[],

      headers:[
        {value:'no'},
        {value:'nama'},
        {value:'nip'},
        {value:'jabatan'},
        {value:'status_online'},
        {value:'last_login'},
        {value:'setting_kegiatan'},
        {value:'login'},
        {value:'lapor_aktivitas_utama'},
        {value:'menit_aktivitas_utama'},
        {value:'lapor_aktivitas_tambahan'},
        {value:'menit_aktivitas_tambahan'},
        {value:'validasi_aktivitas'},
        {value:'review_perilaku'},
        {value:'input_sasaran_kerja'},
        {value:'pangkat'},
        {value:'eselon'},
        {value:'jenis_pegawai'},
        {value:'status'},
        {value:'keterangan'},
        {value:'jml_bawahan_lsg'},
        {value:'aktivitas_login'},
        {value:'detail'},
      ],
      items:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
      selectSKPD:'',
      totalKeseluruhan:0,
      rowPerPage:0,
      page:1,
      tanggal_awal: '',
      tanggal_akhir: '',
      showItem:false,
      fromDateMenu:false,
      endDateMenu: false,
      detailDialog:false,
      detailLoading:false,
      tanggalHistori:'',
      searchHistory:'',
      itemsHistory:[],
      totalKeseluruhanHistory:0,
      detailDialogHistory:false,
      detailLoadingHistory:false,
      detailDataHistory:{},
      loadingHistory:false,
      rowPerPageHistory:0,
      pageHistory:1,
      headersHistory:[
        {text:'No', value:'no'},
        {text:'Aksi', value:'aksi'},
        {text:'Keterangan', value:'keterangan'},
        {text:'Waktu', value:'waktu'}
      ],
      pegNip:'',
      pegNama:''
    }
  },

  watch:{
    page(val){
      this.tableValue.page = val
      this.updateTable(this.tableValue)
    }
  },

  mounted() {
    this.$watch(vm => [vm.searchHistory], val => {
      this.histori({searchH:val[0]})
    })
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD], val => {
      this.page = 1
      this.updateTable({itemsPerPage:this.rowPerPage, kode_skpd:this.selectSKPD, search:val[1], page:this.tableValue.page})
    })
  },

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
    this.getListSKPD()
  },

  computed:{
    filterSemua:{
      get:function(){
        return !this.filterPegawai
      },
      set:function(val){
        this.filterPegawai = !val
      }
    }
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){
      this.loading = true
      this.tableValue = val ? val: this.tableValue
      this.items = []

      var params = {}
      params.kolok = this.tableValue.kode_skpd ? this.tableValue.kode_skpd : '4.03.01'
      params.bulan = this.tableValue.bulan ? this.tableValue.bulan : null
      MonitoringPegawaiService.getData({
        awal:this.tableValue.awal,
        akhir:this.tableValue.akhir,
        skip:false,
        kolok:params.kolok})
      .then((response)=>{
        let res = response.data
        this.items = response.data.data
        this.totalKeseluruhan = response.data.jumlah
        console.log(this.items)
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      })
    },

    getListSKPD(){
      var url = "v1/get-data-skpd2"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_).then(response => {
        let data = []
        _.each(response.data.data, function(value, key, obj){
          data.push({k:key, v: value})
        })
        this.skpd = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    detail(val){
      if(val){
        MonitoringPegawaiService.getDetailPegawai(val[1]).then(response=>{
          var data = response.data
          // this.detailData = data
          this.detailData = {
            "Nama":data.peg_nama,
            "NIP":data.peg_nip,
            "Jenis Jabatan":data.jenis_jabatan == 'S' ? 'Struktural' : (data.jenis_jabatan == 'F' ? 'Fungsional Umum' : (data.jenis_jabatan == 'T' ? 'Fungsional Tertentu' : '')),
            "Jabatan":data.nama_jabatan,
            "Golongan":val[14],
            "Job Value":data.job_value,
            "SOPD":data.unit_kerja.unit_kerja_nama+"<br><label class='ml-5'><b>"+data.satker+"<b></label>",
            "noDisplay":"<br><br><i>Apabila terdapat ketidaksesuaian data, silakan hubungi Admin SIMPEG untuk memperbarui data</i>"
          }
        })
      }
      this.detailLoading = true
      this.detailDialog = true
      setTimeout(() => {
        this.detailLoading = false
      }, 500);
    },

    histori(val){
      this.detailLoadingHistory = true
      this.detailDialogHistory = true
      this.detailDataHistory = {
        "Nama":val[0],
        "NIP":val[1],
      }
      this.pegNip = parseInt(val[1]) ? val[1] : this.pegNip
      this.pegNama = typeof val[0] === 'string' ? val[0] : this.pegNama
      var params = {}
      params.tanggal = this.tanggalHistori
      params.username = this.pegNip
      this.searchHistory = val.searchH ? val.searchH : this.searchHistory
      MonitoringPegawaiService.getHistori({
        page:1,
        perpage:100,
        search:this.searchHistory,
        order_direction:'asc',
        params:params
        })
      .then((response)=>{
        let res = response.data
        this.itemsHistory = response.data.data
        this.totalKeseluruhanHistory = response.data.count
        console.log(this.itemsHistory)
        if (response.data.count > 0){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Histori Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.detailLoadingHistory = false
      })
    },

    cari(){
      var params = {}
      params.awal = this.tanggal_awal
      params.akhir = this.tanggal_akhir
      this.updateTable(params)
    },

    exportExcel(){
      var current = this.$store.getters["user/current"]
      var kode_skpd = this.selectSKPD
      var year = current.year
      var urls = ''
      var base = process.env.VUE_APP_API_URL
      if(base === 'https://mangbagja-demo.rev.web.id/'){
        base = 'https://erk-demo.rev.web.id/'
      }else{
        base = 'https://kinerja.bandung.go.id/'
      }
      urls = base+year+"/api/v1/monitoring-pegawai/export?api=1&kolok="+kode_skpd+"&tanggalfrom="+this.tanggal_awal+"&tanggalto="+this.tanggal_akhir
      window.open(urls,'_blank');
    }
  },
}
</script>

<style lang="scss" scoped>
.pegawai-table{
  tr,th,td {
    border: 1px solid #ddd;
  }
  thead tr th{
    color:white;
  }
}
</style>
