import request from '@/utils/request'
import store from '@/store'
import _g from '../global'

const MonitoringPegawaiService = {
  getData,
  getDetailPegawai,
  getHistori
}

function getData({
    awal,
    akhir,
    skip,
    kolok
}) {
  var current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/admin/monitoring/pegawai/get-data-master',
    method: 'post',
    data: {
      awal:awal,
      akhir:akhir,
      skip:skip,
      kolok:kolok,
      nip:current.username
    }
  })
}

function getDetailPegawai(nip) {
  return request({
    url: '/admin/monitoring/pegawai/get-detail-pegawai',
    method: 'post',
    data: {
      nip: nip
    }
  })
}

function getHistori({
  page,
  perpage,
  search,
  order_direction,
  params
}) {
  var current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/admin/monitoring/pegawai/get-histori',
    method: 'post',
    data: {
      nip: current.username,
      page: page,
      perpage: perpage ? perpage : 20,
      search: search ? search : '',
      order_direction: order_direction ? order_direction : 'asc',
      params: params
    }
  })
}

export default MonitoringPegawaiService